<template>
  <v-app>
    <div class="auth-wrapper auth-v1">
      <div class="auth-inner2 pt-6">
        <v-card v-if="validateToken === false" class="my-12 pt-10 pb-8 px-3">
          <v-container>
            <v-col cols="12">
              <h4 class="text-center">
                パスワード設定の期限が切れています。パスワード設定を再度実施してください。
              </h4>
            </v-col>
            <v-row class="justify-center mt-10">
              <v-col md="6" sm="4">
                <v-btn color="#ff6e40" depressed width="100%" to="/forgot-password">
                  <span style="color: #fff">パスワード設定画面へ</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
        <v-card v-else-if="validateToken === true" class="my-12 pt-10 pb-8 px-3">
          <div class="d-flex justify-center mb-9" style="font-size: 20px; font-weight: bold">
            <span>パスワード設定</span>
          </div>
          <v-row>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="11" md="10">
              <v-row>
                <v-col cols="12" md="5">
                  <div>
                    <span>パスワード</span>
                    <span
                      class="ml-1 pr-1"
                      style="background-color: #ff6e40; color: #fff; font-size: 13px; border-radius: 4px"
                    >
                      必須</span>
                  </div>
                </v-col>
                <v-col cols="12" md="7">
                  <v-text-field
                    v-model="$v.Form.password.$model"
                    hide-details="auto"
                    outlined
                    dense
                    color="#ff6e40"
                    :error-messages="passwordNewErrors"
                    :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show2 ? 'text' : 'password'"
                    autocomplete="new-password"
                    placeholder="半角英数字記号6-16文字"
                    @click:append="show2 = !show2"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="5">
                  <div>
                    <span> パスワード(確認)</span>
                    <span
                      class="ml-1 pr-1"
                      style="background-color: #ff6e40; color: #fff; font-size: 13px; border-radius: 4px"
                    >
                      必須</span>
                  </div>
                </v-col>
                <v-col cols="12" md="7">
                  <v-text-field
                    v-model="$v.Form.confirm_password.$model"
                    hide-details="auto"
                    outlined
                    dense
                    color="#ff6e40"
                    :error-messages="confirmPasswordErrors"
                    :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show3 ? 'text' : 'password'"
                    autocomplete="check-password"
                    placeholder="半角英数字記号6-16文字"
                    @click:append="show3 = !show3"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="mt-9">
                <v-spacer></v-spacer>
                <v-col cols="6" sm="3" class="d-flex justify-end">
                  <v-btn outlined width="100%" depressed @click="back">
                    <span>取消</span>
                  </v-btn>
                </v-col>
                <v-col cols="6" sm="3" class="d-flex justify-end">
                  <v-btn color="#ff6e40" depressed width="100%" :loading="loading" @click="submit">
                    <span style="color: #fff">設定</span>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-spacer></v-spacer>
          </v-row>
        </v-card>
      </div>
    </div>
  </v-app>
</template>

<script>
import {
  mapActions,
} from 'vuex'
import {
  required, sameAs, maxLength, minLength,
} from 'vuelidate/lib/validators'
import agent from '../../api/agent'

export default {
  data: () => ({
    show1: false,
    show2: false,
    show3: false,
    loading: false,
    Form: {
      password: '',
      confirm_password: '',
    },
    validateToken: null,
    apierror: {
      code: '',
      status: '',
      messages: [],
    },
  }),
  validations: {
    Form: {
      password: {
        required,
        maxLength: maxLength(16),
        minLength: minLength(6),
      },
      confirm_password: {
        required,
        sameAs: sameAs('password'),
        maxLength: maxLength(16),
        minLength: minLength(6),
      },
    },
  },
  computed: {
    passwordNewErrors() {
      const errors = []
      if (!this.$v.Form.password.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.password.maxLength && errors.push('最大16文字までです！')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.password.minLength && errors.push('最小6文字までです！')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.password.required && errors.push('必須項目')
      // eslint-disable-next-line no-unused-expressions

      return errors
    },

    confirmPasswordErrors() {
      const errors = []
      if (!this.$v.Form.confirm_password.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.confirm_password.maxLength && errors.push('最大16文字までです！')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.confirm_password.minLength && errors.push('最小6文字までです！')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.confirm_password.required && errors.push('必須項目')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.confirm_password.sameAs && errors.push('上記「パスワード」と一致していません。')

      return errors
    },
  },
  created() {
    this.doForm()
  },
  $route() {
    this.doForm()
  },
  methods: {
    ...mapActions('auth', ['ResetPassword', 'checkToken']),

    doForm() {
      this.apierror.status = ''
      this.Form.token = this.$route.query.token
      this.Form.email = this.$route.query.email
      this.$store.commit('app/setOverlayStatus', true)
      agent.Auth.checkToken(this.Form)
        .then(() => {
          this.validateToken = true
        })
        .catch(error => {
          this.validateToken = false
          this.apierror.status = error.response.data.status
          this.apierror.code = error.response.data.code
          this.apierror.messages = error.response.data.message
        })
        .finally(() => {
          this.$store.commit('app/setOverlayStatus', false)
        })
    },

    submit() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.loading = true

        this.ResetPassword(this.Form)
          .then(() => {
            this.back()
          })
          .catch(error => {
            this.apierror.status = error.response.data.status
            this.apierror.code = error.response.data.code
            this.apierror.messages = error.response.data.message
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    back() {
      this.$router.push('/login')
    },
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
